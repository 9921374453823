import { getLoginURLWithParams } from "./utils/common";

export const BASE_PATH = "/";

export const ROUTES = {
  BASE: `${BASE_PATH}`,
  LOGIN: `${BASE_PATH}login`,
  LOGIN_WITH_PARAMS: getLoginURLWithParams(),
  SIGN_UP: `${BASE_PATH}sign-up`,
  INVITED_SIGN_UP: `${BASE_PATH}invited-sign-up`,
  SCHOOL: `${BASE_PATH}school`,
  UNIVERSITY_WIDGET: `${BASE_PATH}university`,
  UNIVERSITY_WIDGET_CHAT_POPUP: `${BASE_PATH}university/chat-popup`,
  UNIVERSITY_WIDGET_CHAT: `${BASE_PATH}university/chat`,
  AMBASSADOR_SIGN_UP: `${BASE_PATH}ambssador-sign-up`,
  AMBASSADOR_ACCEPT_INVITE: `${BASE_PATH}ambassador-accept-invite`,
  DASHBOARD: `${BASE_PATH}dashboard`,
  CONFIRM_EMAIL: `${BASE_PATH}edit-email`,
  FORGOT_PASSWORD: `${BASE_PATH}forgot-password`,
  RESET_PASSWORD: `${BASE_PATH}reset-password`,
  CHAT: {
    PATH: `${BASE_PATH}chat/`,
    AMBASSADORS: `${BASE_PATH}chat/ambassadors`,
  },
  COMMUNITY: {
    PATH: `${BASE_PATH}community/`,
    TOPICS: `${BASE_PATH}community/topics`,
  },
  MESSAGES: `${BASE_PATH}messages`,
  DISCOVER: {
    PATH: `${BASE_PATH}discover/`,
    WEBINARS: `${BASE_PATH}discover/webinars`,
    RESOURCES: `${BASE_PATH}discover/resources`,
    STUDENT_SERVICES: `${BASE_PATH}discover/services`,
    EMPLOYMENT_DATA_HUB: `${BASE_PATH}discover/employment-data-hub`,
    COURSES: `${BASE_PATH}discover/courses`,
  },
  TOOLS: {
    PATH: `${BASE_PATH}tools/`,
    CHECKLISTS: `${BASE_PATH}tools/checklists`,
  },
  PROFILE: `${BASE_PATH}profile`,
  NOTIFICATIONS: `${BASE_PATH}notifications`,
  NOTIFICATIONS_SETTINGS: `${BASE_PATH}notifications/settings`,
  NOTIFICATIONS_UNSUBSCRIBE: `${BASE_PATH}notification/unsubscribe`,
  HELP_CENTER: `${BASE_PATH}help-center`,
  PRIVACY_POLICY: `${BASE_PATH}privacy-policy`,
  TERMS_AND_CONDITIONS: `${BASE_PATH}terms-and-conditions`,
  LINKEDIN: `${BASE_PATH}linkedin`,
  CAMPUS_MAP: `${BASE_PATH}campus-map`,
  CALCULATOR: `${BASE_PATH}calculator`,
};

export const UNPROTECTED_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.SIGN_UP,
  ROUTES.INVITED_SIGN_UP,
  ROUTES.AMBASSADOR_SIGN_UP,
  ROUTES.AMBASSADOR_ACCEPT_INVITE,
  ROUTES.CONFIRM_EMAIL,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.NOTIFICATIONS_UNSUBSCRIBE,
];

export const WIDGET_PATHS = [
  ROUTES.UNIVERSITY_WIDGET,
  ROUTES.UNIVERSITY_WIDGET_CHAT,
  ROUTES.UNIVERSITY_WIDGET_CHAT_POPUP,
];
