import { ApiWrapper, REQUEST } from "./index";

export const getCalculatorFields = async (school_id = null) => {
  return await ApiWrapper({
    url: `cost_calculators?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

export const getScholarships = async (school_id = null) => {
  return await ApiWrapper({
    url: `scholarships?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};
