import React, { useMemo, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Calculator.module.scss";
import CalculatorItem from "../../molecules/CalculatorItem/CalculatorItem";
import CalculatorSummary from "../../molecules/CalculatorSummary/CalculatorSummary";
import Card from "../../atoms/Card/Card";
import CalculatorInfo from "../../molecules/CalculatorInfo/CalculatorInfo";
import InfoIconPopup from "../../atoms/InfoIconPopup/InfoIconPopup";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { exportCSV, exportPDF } from "../../../utils/common";
import {
  resetCalculatorData,
  setCostValues,
  setFundValues,
  setInitialValues,
} from "../../../redux/reducers/calculatorReducer";
import { getCalculatorFields, getScholarships } from "../../../api/calculator";
import { ROUTES } from "../../../routes";

const cx = createModuleStyleExtractor(styles);

const FIELD_CATEGORY_TYPE = {
  COST_OF_ATTENDANCE: "cost_of_attendance",
  SOURCE_OF_FUNDS: "source_of_fund",
};

export default function Calculator() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedSchool = {}, permissions = {} } = useSelector(
    (state) => state.userState
  );
  const calculatorState = useSelector((state) => state.calculatorState);
  const {
    school_id = null,
    costValues = {},
    fundValues = {},
  } = calculatorState;

  const [costFields, setCostFields] = useState([]);
  const [fundFields, setFundFields] = useState([]);
  const [calculatorLoader, setCalculatorLoader] = useState(true);
  const [scholarships, setScholarships] = useState([]);
  const [scholarshipsLoader, setScholarshipsLoader] = useState(true);

  useEffect(() => {
    // Check permission of module
    if (permissions["cost_calculator"]) {
      fetchCalculatorData();
      fetchScholarships();
    } else {
      // Do not allow to access if feature permission is not granted
      setTimeout(() => {
        // Delay added to smoothly tranistion and avoid bug
        navigate(ROUTES.DASHBOARD);
      }, [1000]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool, permissions, navigate]);

  const fetchCalculatorData = async () => {
    setCalculatorLoader(true);
    const response = await getCalculatorFields(selectedSchool?.id);

    if (response.success && response?.data) {
      const { cost_calculators = [] } = response.data;
      const _costFields = cost_calculators.filter(
        (record) =>
          record.category_type === FIELD_CATEGORY_TYPE.COST_OF_ATTENDANCE
      );
      const _fundFields = cost_calculators.filter(
        (record) => record.category_type === FIELD_CATEGORY_TYPE.SOURCE_OF_FUNDS
      );

      const isSchoolChanged = school_id !== selectedSchool.id ? true : false;

      const _costValues = {};
      _costFields.forEach((field) => {
        // Set fixed amount values
        if (field.adjustment_type === "fixed")
          _costValues[field.id] = field.amount;
        // Refill preserved values for school
        else if (!isSchoolChanged && costValues[field.id])
          _costValues[field.id] = costValues[field.id];
      });

      const _fundValues = {};
      _fundFields.forEach((field) => {
        // Set fixed amount values
        if (field.adjustment_type === "fixed")
          _fundValues[field.id] = field.amount;
        // Refill preserved values for school
        else if (!isSchoolChanged && fundValues[field.id])
          _fundValues[field.id] = fundValues[field.id];
      });

      dispatch(
        setInitialValues({
          school_id: selectedSchool?.id,
          costValues: _costValues,
          fundValues: _fundValues,
        })
      );
      setCostFields([..._costFields]);
      setFundFields([..._fundFields]);
    } else {
      dispatch(resetCalculatorData());
    }

    setCalculatorLoader(false);
  };

  const fetchScholarships = async () => {
    setScholarshipsLoader(true);
    const response = await getScholarships(selectedSchool?.id);

    if (response.success && response?.data) {
      setScholarships(response.data?.scholarships ?? []);
    } else {
      setScholarships([]);
    }
    setScholarshipsLoader(false);
  };

  const changeCostValue = (id, value) => {
    dispatch(setCostValues({ id, value }));
  };

  const changeFundValue = (id, value) => {
    dispatch(setFundValues({ id, value }));
  };

  const calculateSum = (array, values) => {
    return array.reduce(
      (partialSum, item) =>
        partialSum +
        parseFloat(
          values[item.id]?.toString().length > 0 ? values[item.id] : 0 ?? 0
        ),
      0
    );
  };

  const totalCostOfAttendance = useMemo(() => {
    return calculateSum(costFields, costValues);
  }, [costValues, costFields]);

  const totalSourceOfFunds = useMemo(() => {
    return calculateSum(fundFields, fundValues);
  }, [fundValues, fundFields]);

  const exportFields = (array, values, delimeter) => {
    return array.reduce(
      (text, item) =>
        text +
        item.name +
        `${delimeter}$${parseFloat(
          values[item.id]?.toString().length > 0
            ? parseFloat(values[item.id])
            : 0 ?? 0
        )}\n`,
      ``
    );
  };

  const exportCSVData = () => {
    const delimeter = ",";
    const costValuesText = exportFields(costFields, costValues, delimeter);
    const fundValuesText = exportFields(fundFields, fundValues, delimeter);
    const exportText = `Cost of attendance\n\n${costValuesText}\nSource of funds\n\n${fundValuesText}\nAmount needed${delimeter}$${amountNeeded}`;
    exportCSV({
      text: exportText,
      filename: "Tuition_Calculator",
    });
  };

  const exportPDFData = () => {
    const delimeter = " ";
    const costValuesText = exportFields(costFields, costValues, delimeter);
    const fundValuesText = exportFields(fundFields, fundValues, delimeter);
    const exportText = `Cost of attendance\n\n${costValuesText}\nSource of funds\n\n${fundValuesText}\nAmount needed${delimeter}$${amountNeeded}`;
    exportPDF({
      text: exportText,
      filename: "Tuition_Calculator",
    });
  };

  const amountNeeded = totalCostOfAttendance - totalSourceOfFunds;

  return (
    <div className={cx("o-calculator")}>
      {calculatorLoader ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <ShimmerThumbnail height={600} rounded />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ShimmerThumbnail height={600} rounded />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <ShimmerThumbnail height={600} rounded />
          </Grid>
        </Grid>
      ) : (
        <>
          <h1 className={cx("o-calculator__title")}>Tuition Calculator</h1>
          <div className={cx("o-calculator__export")}>
            <span>Export as </span>
            <Button onClick={exportPDFData} modifier={ButtonModifier.WHITE}>
              PDF
            </Button>
            <Button onClick={exportCSVData} modifier={ButtonModifier.WHITE}>
              Excel
            </Button>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              {scholarshipsLoader ? (
                <ShimmerThumbnail height={300} rounded />
              ) : (
                <CalculatorInfo scholarships={scholarships} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <h2>
                Cost of attendance{" "}
                <InfoIconPopup
                  margin={"0px"}
                  popoverContent={<>Cost of attendance</>}
                />
              </h2>
              <Card>
                {costFields.length > 0 ? (
                  <>
                    {costFields.map((item) => (
                      <CalculatorItem
                        item={item}
                        value={costValues[item.id]}
                        onChange={(value) => changeCostValue(item.id, value)}
                      />
                    ))}
                  </>
                ) : (
                  <div className="text-center">
                    No cost of attendance has been added.
                  </div>
                )}
              </Card>
              <h2>Source of funds</h2>
              <Card>
                {fundFields.length > 0 ? (
                  <>
                    {fundFields.map((item) => (
                      <CalculatorItem
                        item={item}
                        value={fundValues[item.id]}
                        onChange={(value) => changeFundValue(item.id, value)}
                      />
                    ))}
                  </>
                ) : (
                  <div className="text-center">
                    No source of funds has been added.
                  </div>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <CalculatorSummary
                totalCostOfAttendance={totalCostOfAttendance}
                totalSourceOfFunds={totalSourceOfFunds}
                amountNeeded={amountNeeded}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
