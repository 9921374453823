import React from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Button.module.scss";
const cx = createModuleStyleExtractor(styles);

export const ButtonModifier = {
  PRIMARY: "a-button--primary",
  PRIMARY_DISABLED: "a-button--primary-disabled",
  SECONDARY: "a-button--secondary",
  DANGER: "a-button--danger",
  WARNING: "a-button--warning",
  SUCCESS: "a-button--success",
  TEST_BUTTON: "a-some-test-button",
  WHITE: "a-button--white",

  CHAT_SEND_BUTTON: "a-button--chat-send",
  CHAT_SEND_BUTTON_DISABLED: "a-button--chat-send--disabled",
  WEBINAR: "a-button--webinar",
  RESOURCE: "a-button--resource",
  STUDENT_SERVICES: "a-button--student-services",
  INVITE_BUTTON: "a-button---invite",
  INVITE_SKIP_BUTTON: "a-button--skip",
  CHECKLIST_ADD: "a-button--checklist-add",
  CHECKLIST_SAVE: "a-button--checklist-save",
  WEBINAR_SEARCH_FILTER: "a-button--webinar-search-filter",
  WEBINAR_DETAIL_MODAL: "a-button--webinar-detail-modal",
  DISCOVER_NETWORK_BUTTON: "a-button--discover-network-button",

  JUMP_TO_MESSAGES: "a-button--discover-network-to-messages",
  CLEAR_NETWORK_FILTERS: "a-button--discover-network-clear-filters",
  NEW_CHAT_BUTTON_FROM_CARD: "a-button--discover-network-card-to-messages",
  DISCOVER_NETWORK_REQUEST_MEETING:
    "a-button--discover-network-request-meeting",
  USER_PROFILE_REQUEST_MEETING: "a-button--user-profile-request-meeting",
  NOTIFICATIONS_MANAGE: "a-button--notifications-manage",
  NOTIFICATIONS_CLEAR: "a-button--notifications-clear",
  NOTIFICATIONS_ACTION: "a-button--notifications-action",
  NOTIFICATIONS_ACTION_SMALL: "a-button--notifications-action-small",
  NOTIFICATIONS_DELETE: "a-button--notifications-delete",
  NOTIFICATIONS_DRAWER_HEADER: "a-button--notifications-drawer-header",
  NOTIFICATIONS_DRAWER_VIEW: "a-button--notifications-drawer-view",
  TOPICS_PARTICIPANT_LIST_VIEW: "a-button--topics-participant-list-view",
  PROFILE_HEADER_PRIMARY: "a-button--profile-header-primary",
  PROFILE_HEADER_SECONDARY: "a-button--profile-header-secondary",
  PROFILE_UPLOAD: "a-button--profile-img-upload",
  PROFILE_SUBMIT: "a-button--profile-submit",
  TOPICS_UNFOLLOW: "a-button--topics-unfollow",
  WIDGET_SIGN_UP_BAR: "a-button--widget-sign-up-bar",
  WIDGET_SIGN_UP_OVERLAY: "a-button--widget-sign-up-overlay",
  WIDGET_CARD_BUTTON: "a-button--widget-card-button",
  WIDGET_CHATLIST_CARD_BUTTON: "a-button--widget-chatlist-card-button",
  WEBINAR_TAB_BUTTON: "a-button--webinar-tab",
  WEBINAR_TAB_ACTIVE_BUTTON: "a-button--webinar-tab--active",
  WEBINAR_BUTTON_UPCOMMING_PAST: "a-button--webinar-list-upcoming-past",
  WEBINAR_BUTTON_REGISTERED: "a-button--webinar-list-registered",
  SLIDER_ACTION: "a-button--slider-action",
};

export const Button = ({
  onClick,
  children,
  modifier,
  type,
  buttonRef,
  disabled = false,
  isSubmitting = false,
}) => (
  <button
    ref={buttonRef}
    disabled={disabled || isSubmitting}
    className={cx(
      "a-button",
      modifier
        ? {
            [modifier]: true,
          }
        : disabled || isSubmitting
        ? {
            [ButtonModifier.PRIMARY_DISABLED]: true,
          }
        : {}
    )}
    type={type}
    onClick={onClick}
  >
    {children}
    {isSubmitting && <span className={cx("a-button__loader")} />}
  </button>
);
