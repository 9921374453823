import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
// import { createLogger } from "redux-logger";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import preservedReducer from "./reducers/preservedReducer";
import userReducer from "./reducers/userReducer";
import webSocketReducer from "./reducers/webSocketReducer";
import chatReducer from "./reducers/chat/reducers";
import notificationReducer from "./reducers/notificationReducer";
import calculatorReducer from "./reducers/calculatorReducer";

// const logger = createLogger({});

const persistConfig = {
  key: "interstride_admissions",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["chatStore", "webSocketStore"],
};

const rootReducer = combineReducers({
  preservedState: preservedReducer,
  userState: userReducer,
  chatStore: chatReducer,
  notificationState: notificationReducer,
  webSocketStore: webSocketReducer,
  calculatorState: calculatorReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware: [logger],
  middleware: [],
});

export const persistor = persistStore(store);
