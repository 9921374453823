import React, { memo, useState, useEffect } from "react";
import moment from "moment";

import { getWebinarsList } from "../../../api/widget";
import { validateString } from "../../../utils/validations";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetWebinars.module.scss";
import Card from "../../atoms/Card/Card";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { createtTime } from "../../helpers/helper";
import TruncateText from "../../atoms/TruncateText/TruncateText";

import TimeClockIcon from "../../../assets/Icons/TimeClockIcon";
import ProfileIcon from "../../../assets/Icons/ProfileIcon";
import ListingEffect from "../../atoms/ListingEffect/ListingEffect";
import WebinarImage from "../../../assets/images/Webinar.png";

const cx = createModuleStyleExtractor(styles);

const WidgetWebinars = ({ schoolData = {}, onSignupClick = () => {} }) => {
  const [webinars, setWebinars] = useState([]);
  const [loader, setLoader] = useState(true);

  const VISIBLE_RECORDS_COUNT = 5;

  useEffect(() => {
    const getWebinars = async () => {
      setLoader(true);
      const response = await getWebinarsList(schoolData?.token);
      if (response.success) {
        setWebinars(response.data?.past_webinars ?? []);
        setLoader(false);
      } else {
        setLoader(false);
      }
    };

    getWebinars();
  }, [schoolData?.token]);

  return (
    <div className={cx("m-widget-webinars")}>
      <div className={cx(["m-widget-webinars__listing"])}>
        {loader ? (
          <ListingEffect count={5} sideSpace={false} />
        ) : (
          <>
            {webinars.length === 0 ? (
              <Card>
                <div className={cx("m-widget-webinars-no-item")}>
                  <p>Looks like there are no webinars.</p>
                </div>
              </Card>
            ) : (
              webinars.map((webinar, index) => (
                <Card key={index}>
                  {index >= VISIBLE_RECORDS_COUNT && (
                    <div
                      className={cx("m-widget-webinars__listing__overlay")}
                    />
                  )}
                  <div className={cx("m-widget-webinars-item-wrapper")}>
                    <div className={cx("m-widget-webinars-item-img-left")}>
                      <img
                        src={
                          webinar.webinar_image &&
                          !validateString(webinar.webinar_image)
                            ? webinar.webinar_image
                            : WebinarImage
                        }
                        alt="webinar"
                      />
                    </div>
                    <div
                      className={cx("m-widget-webinars-item-content-wrapper")}
                    >
                      <h2>{webinar.name}</h2>
                      <h3>
                        {moment(webinar.webinar_date).format(
                          "dddd, MMMM Do YYYY"
                        )}
                      </h3>
                      <p
                        className={cx(
                          "m-widget-webinars-item-content-wrapper-duration-time"
                        )}
                      >
                        <TimeClockIcon />
                        <span>
                          {`${moment
                            .utc(webinar.webinar_time)
                            .format("hh:mm a")} - ${createtTime(
                            webinar.webinar_time,
                            webinar && webinar.duration
                              ? webinar.duration.split(" ")[0]
                              : 0,
                            "minutes"
                          )}`}{" "}
                          ({webinar.webinar_time_zone_short})
                        </span>
                      </p>

                      {!validateString(webinar.presenter) && (
                        <p
                          className={cx(
                            "m-widget-webinars-item-content-wrapper-webinar-presenter"
                          )}
                        >
                          <ProfileIcon />
                          <span>{webinar.presenter}</span>
                        </p>
                      )}

                      {!validateString(webinar.description) && (
                        <div
                          className={cx(
                            "m-widget-webinars-item-content-wrapper-description"
                          )}
                        >
                          <TruncateText
                            text={webinar.description}
                            length={300}
                          />
                        </div>
                      )}
                    </div>
                    <Button
                      modifier={ButtonModifier.WEBINAR}
                      onClick={onSignupClick}
                    >
                      View Recording
                    </Button>
                  </div>
                </Card>
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default memo(WidgetWebinars);
