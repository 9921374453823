import WelcomeTutorialGuideIcon from "../assets/svg/help-center/welcome-tutorial-guide-icon.svg";
import GeneralGuideIcon from "../assets/svg/help-center/general-guide-icon.svg";
import ProfileGuideIcon from "../assets/svg/help-center/profile-guide-icon.svg";
import ChatGuideIcon from "../assets/svg/help-center/chat-guide-icon.svg";
import ResourceGuideIcon from "../assets/svg/help-center/resource-guide-icon.svg";
import MasterclassModule1Icon from "../assets/svg/masterclass/Masterclass-module1.svg";
import MasterclassModule2Icon from "../assets/svg/masterclass/Masterclass-module2.svg";
import MasterclassModule3Icon from "../assets/svg/masterclass/Masterclass-module3.svg";
import MasterclassModule4Icon from "../assets/svg/masterclass/Masterclass-module4.svg";
import MasterclassModule5Icon from "../assets/svg/masterclass/Masterclass-module5.svg";
import MasterclassModule6Icon from "../assets/svg/masterclass/Masterclass-module6.svg";

export const NOTIFICATION_FILTERS = [
  {
    title: "Networking",
    value: "networking",
  },
  {
    title: "Community",
    value: "topics",
  },
  {
    title: "Webinar",
    value: "webinars",
  },
  {
    title: "Resources",
    value: "other_resources",
  },
  {
    title: "Services",
    value: "student_services",
  },
  {
    title: "Calendar",
    value: "calendar",
  },
];

export const TERMS_AND_CONDITIONS = [
  {
    id: 1,
    category: "Overview",
    details: `Please read these Terms of Use (“Terms”, “Terms of Use”) carefully before using the Interstride mobile application or website (the “Service”) operated by Interstride LLC (“Interstride”, “us”, “we”, or “our”).<br/>
    The following are terms of a legal agreement between you (“You” or “Your”) and Interstride. Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms.<br/>
    These Terms apply to all visitors, users and others who wish to access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.`,
  },
  {
    id: 2,
    category: "Privacy",
    details: `Your privacy is very important to us. Your use of our Services is also subject to our Privacy Policy.`,
  },
  {
    id: 3,
    category: "Accounts",
    details: `You need to have an account to use the Service. You can create an account with Interstride if you are a student at an institution that has partnered with Interstride or if you are an Employer looking to hire students using the Service. Institutions that have partnered with Interstride have administrative access to the Service.<br/><br/>
    When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service. You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.`,
  },
  {
    id: 4,
    category: "Communications",
    details: `By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.`,
  },
  {
    id: 5,
    category: "Content",
    details: `Our Service allows you to post, upload, input, link, store, share and otherwise make available (“Share”) certain information, text, graphics, videos, or other material (“Content”). You are responsible for the Content that you Share on or through the Service, including its legality, reliability, and appropriateness. By Sharing Content on or through the Service, you represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the Sharing of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright. You retain any and all of your rights to any Content you Share through the Service and you are responsible for protecting those rights.<br/><br/>
    We take no responsibility and assume no liability for Content you Share or for any Content you or any third-party Shares on or through the Service. However, by Sharing Content using the Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms. Interstride has the right but not the obligation to monitor and edit all Content provided by users. In addition, Content found on or through this Service are the property of Interstride or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.`,
  },
  {
    id: 6,
    category: "Copyright Policy",
    details: `We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity. If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to contact@interstride.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims” You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through the Service on your copyright.<br/><br/>
    DMCA Notice and Procedure for Copyright Infringement Claims<br/><br/>
    You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail): identification of the URL or other specific location on the Service where the material that you claim is infringing is located; your address, telephone number, and email address; a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. You can contact our Copyright Agent via email at contact@interstride.com`,
  },
  {
    id: 7,
    category: "Intellectual Property",
    details: `The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Interstride and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Interstride.`,
  },
  {
    id: 8,
    category: "Job Portal",
    details: `The Service also assists with the hiring process. You acknowledge and understand that Employers on the Interstride platform are responsible for their job postings, job requirements, and hiring needs. Each Employer is responsible for complying with applicable laws regarding employment and hiring practices, including Title VII. Interstride disclaims all liability and makes no warranty that the Employer’s use of the Services complies with Title IV or any similar law.<br/><br/>
    Interstride does not control the hiring or decision-making process regarding each Employer’s hiring efforts. Interstride is not an employment agency but rather provides a platform, network, and various tools to assist with the hiring needs of Employers.`,
  },
  {
    id: 9,
    category: "Links To Other Websites",
    details: `Our Service contains links to third party websites or services that are not owned or controlled by Interstride. Interstride has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites. You acknowledge and agree that Interstride shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party web sites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.`,
  },
  {
    id: 10,
    category: "Termination",
    details: `We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. If you wish to terminate your account, you may simply discontinue using the Service. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.`,
  },
  {
    id: 11,
    category: "Indemnification",
    details: `You agree to defend, indemnify and hold harmless Interstride and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.`,
  },
  {
    id: 12,
    category: "Limitation Of Liability",
    details: `In no event shall Interstride, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.`,
  },
  {
    id: 13,
    category: "Disclaimer",
    details: `Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. Interstride LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.`,
  },
  {
    id: 14,
    category: "Exclusions",
    details: `Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.`,
  },
  {
    id: 15,
    category: "Governing Law",
    details: `These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.`,
  },
  {
    id: 16,
    category: "Changes",
    details: `Interstride may revise the Terms of Use and Privacy Policy at any time without notice to you. The revised Terms of Use and Privacy Policy will be effective when posted. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.`,
  },
  {
    id: 17,
    category: "Employers",
    details: `In addition to the standard Terms of Use above, the following additional terms and conditions apply to all Employers (including recruiters and third parties using the Service to hire potential employees) (“you” or “your” refers to the Employer in the section below):<br/>
    <ul>
    <li>When you create an Interstride account, you agree to allow Interstride to verify your identity.</li>
    <li>As an Employer, your account is for business use and not for personal use.</li>
    <li>As an Employer using the Service, you may have access to student information and data. You are prohibited from disclosing or sharing this information with other parties, and agree to keep student data confidential. You shall only use student data to provide employment related services to the student and not use, sell, or disclose such data for any purposes including data analytics, data monetization, advertising, marketing or any other purpose. You agree to handle and maintain collected student data with equivalent or superior standards as those described in our Privacy Policy, including the requirements of the General Data Protection Regulation (GDPR).</li>
    <li>You also agree not to use the Service to send spam or other unauthorized communications. </li>
    <li>You agree that through your use of the Service you will maintain fair and equitable recruiting and hiring practices. </li>
    <li>You agree to allow Interstride to market and promote your job posting or hiring needs through its network and as it sees fit.</li>
    <li>Interstride, in its sole discretion, may add labels or badges to Employers based on their responsiveness, hiring needs, activeness on the Interstride platform.</li>
    <li>The Employer is responsible for the contents of its job posting, application form, screener questions.</li>
    <li>You agree that Interstride may reject or remove any job posting, any part of any Employer for any or no reason.</li>
    <li>Interstride does not guarantee applicants for your job posting or delivery of any communication or application material or that there will be no mistakes in the transmission of information.</li>
    <li>Interstride provides no guarantee as to the qualifications or interest in your job posting by students or alumni on the Interstride platform.</li>
    <li>You agree and consent that Interstride may review and present potential applicants to your job posting based on information provided by the applicant and based on Interstride’s analysis and opinion</li>
    </ul>
    You shall indemnify, defend and hold harmless Interstride, its agents, affiliates, and licensors from any third-party claim or liability (including without limitation reasonable legal fees) arising out of any of your job postings on the Interstride platform.`,
  },
  {
    id: 18,
    category: "Contact Us",
    details: `If you have any questions about these Terms, please contact us at <a href="mailto:contact@interstride.com">contact@interstride.com</a>.<br/><br/>
    Mailing address: 5127 Meadow Creek Dr., Austin, TX 77085 `,
  },
];

export const PRIVACY_POLICY = [
  {
    id: 1,
    category: "Overview",
    details: `This page is used to inform mobile app users and website visitors regarding Interstride LLC’s (“Interstride”) policies with the collection, use, and disclosure of Personal Information in connection with use of the Interstride mobile application or website (the “Service”). Through your interaction with and use of the Service, Interstride may collect Personal Information, which is information that identifies an individual or relates to an identifiable individual.<br/><br/>
    If you choose to use our Service, then you agree to the collection and use of Personal Information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not sell or share your information with third parties except as described in this Privacy Policy.<br/><br/>
    The terms used in this Privacy Policy have the same meanings as in Interstride’s Terms of Use. This policy is part of the Terms of Use. By using the Service, you agree to both the Terms of Use and this Privacy Policy, if you do not agree, please do not use the Service.`,
  },
  {
    id: 2,
    category: "Information Collection and Use",
    details: `For a better experience, while using our Service, we may require you to provide us with certain Personal Information, including but not limited to Email address, Name (first and last name), Telephone number. Such information may also include demographic information, geolocation information (IP Address), educational information, or commercial information. The information that we request will be retained by us and used as described in this privacy policy.<br/><br/>
    The Service does use third party services that may collect information used to identify you.<br/><br/>
    Links to privacy policies of third-party service providers used by the Service include but are not limited to:<br/>
    <ul>
    <li>Google</li>
    <li>LinkedIn</li>
    <li>Crunchbase</li>
    <li>Wikipedia</li>
    <li>Indeed</li>
    <li>U.S. Department of Labor</li>
    </ul>`,
  },
  {
    id: 3,
    category: "Personal and Other Information",
    details: `Through your interaction with and use of the Service, Interstride may collect Personal Information, including, but not limited to:<br/>
    <ul>
    <li>Your name and physical address, email addresses, and telephone numbers</li>
    <li>Traits, skills, knowledge or demographic attributes</li>
    <li>Information regarding your interests, job search inquiries and networking</li>
    <li>Webchat data that is captured by the system, encrypted and stored for up to 30 days. Users should not enter sensitive information into the webchat services</li> 
    </ul>
    Interstride may also collect Other Information through your interaction with and use of the Service and non-Interstride websites, which does not directly reveal your identity. Other Information may include, but is not limited to:<br/>
    <ul>
    <li>Unique IDs such as a cookie placed on your computer, mobile or device IDs</li>
    <li>Internet Protocol address (“IP address”) and information derived from your IP address such as your geographic location</li>
    <li>Usage data such as modules you accessed, webpages clicked, websites and content areas visited, date and time of activities</li>
    </ul>
    <b>Interstride uses and shares the Personal Information and Other Information it collects (unless otherwise restricted by applicable law), to:</b><br/>
    <ul>
    <li>Engage in Transactions. Interstride may use Personal Information to engage in transactions with you.</li>
    <li>Engage with your University. Interstride may use Personal Information and Other Information to provide insight and statistics to your institution.</li>
    <li>Provide Support or Other Services. Interstride may use your Personal Information to provide you support, and other similar operational communications and in helping identify potential employment opportunities for you.</li>
    <li>Tailor Marketing to Your Needs. Interstride may use your Personal Information and Other Information to notify you about new application releases and service developments.</li>
    <li>Support Student Online Community. Interstride permits you to participate in interactive discussions; post comments, opportunities or other content; communicate directly with another users; or otherwise engage in networking activities. Some of these services are moderated and all may be accessed for technical reasons. Interstride does not control the content that users post to this forum. You should carefully consider whether you wish to submit Personal Information to these forums.</li>
    <li>Interact with You on Third Party Social Networks. Interstride may use your Personal Information to interact with you on third-party social networks. Interstride’s interactions with you on a third-party social network would be subject to that network’s privacy policies and terms of use.</li>
    <li>Select Content, Improve Quality and Facilitate Use of the Service. Interstride may use your Personal Information to help create and personalize content on our Service, facilitate your use of the Service, evaluate and improve our Service.</li>
    </ul>
    <b>Interstride will only share your personal information under the following circumstances:</b><br/>
    <ul>
        <li>With your consent. For example, you may choose to make your profile available to employers through the Service. You may choose to apply to a job and share your profile information, resume and transcript with employers. You may choose to make your profile available to students at your institution or with students outside your institution.</li>
        <li>With your institution — Your institution administrators, faculty, staff, students, and alumni will be able to access and view personal data uploaded by you.</li>
        <li>With Vendors. Vendors that are contractually engaged by Interstride to provide services. These companies must comply with confidentiality and non-disclosure obligations with regards to any Personal Information.</li>
        <li>With Law Enforcement. Interstride may disclose your Personal Information as we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, including public and government authorities outside your country of residence, for national security and/or law enforcement purposes; (d) to enforce our terms and conditions; and (e) to allow us to pursue available remedies or limit the damages that we may sustain.</li>
        <li>With Professional Services. In the event of a reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings), we may transfer the Personal Information we have collected to the relevant third party.</li>
    </ul>`,
  },
  {
    id: 4,
    category: "Other Information",
    details: `We may use, transfer, and disclose Other Information we collect for any purpose, except where applicable law requires otherwise. If we are required to treat Other Information as Personal Information under applicable law, then we will only use it in the same way that we are permitted to use and disclose Personal Information.`,
  },
  {
    id: 5,
    category: "Retention and Deletion",
    details: `Interstride will retain your Personal Information for as long as your account is active with the Service; as needed to provide you Service: or as needed for the purposes outlined in this Policy.`,
  },
  {
    id: 6,
    category: "FERPA – Family Rights and Privacy Act",
    details: `Interstride complies with FERPA guidelines and allows students the following rights regarding their educational records:
    <ul>
    <li>The right to access the student’s educational records to the extent any are stored on the Service.</li>
    <li>The right to demand the student’s educational records be disclosed only with student consent.</li>
    <li>The right to amend/modify the student’s educational records and Personal Information, and deactivate their account.</li>
    </ul>`,
  },
  {
    id: 7,
    category: "Log Data",
    details: `We want to inform you that whenever you use our Service, in a case of an error in the Service we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the application when utilizing our Service, the time and date of your use of the Service, and other statistics.`,
  },
  {
    id: 8,
    category: "Cookies",
    details: `Cookies are files with small amount of data that are commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your device internal memory.<br/><br/>
    This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collection information and to improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.`,
  },
  {
    id: 9,
    category: "Security",
    details: `We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.`,
  },
  {
    id: 10,
    category: "Do Not Track Requests",
    details: `We do not respond to the browser “Do Not Track” (DNT) signal if enabled by the user in their web browser or mobile application. When we set or read cookies on non-Interstride services that have been integrated into our Service and display features, we do so, for example, to provide a personalized search experience to users on our mobile application. We do not share any information about your use of Interstride with those third parties. When third parties that we have integrated into Interstride sites set or read their own third-party cookies, they may or may not respond to the DNT signal.`,
  },
  {
    id: 11,
    category: "Links to Other Sites",
    details: `The Service may make available links to other websites. When you click on such links, you may leave our Service. The Service may also make available Company Pages, which may contain information provided by third-party Employers. WE ARE NOT RESPONSIBLE FOR THE INFORMATION COLLECTION, USE, DISCLOSURE OR OTHER PRIVACY PRACTICES OF ANY THIRD PARTY, INCLUDING OUR AFFILIATES, THIRD-PARTY SERVICE PROVIDERS, ANY EMPLOYERS (INCLUDING ANY EMPLOYER THAT PROVIDES A COMPANY PAGE), ANY THIRD-PARTY SOCIAL MEDIA PLATFORM, ANY THIRD PARTY MAKING AVAILABLE THE DEVICES OR OPERATING SYSTEMS FOR WHICH THE SITE IS AVAILABLE, AND ANY THIRD PARTY OPERATING ANY WEBSITE TO WHICH THE SITE CONTAINS A LINK. YOU MAY HAVE RIGHTS DIRECTLY ENFORCEABLE AGAINST THESE THIRD PARTIES SO YOU SHOULD CONSIDER THEIR PRIVACY POLICIES TO LEARN MORE.`,
  },
  {
    id: 12,
    category: "Children’s Privacy",
    details: `These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take necessary actions.`,
  },
  {
    id: 13,
    category: "Acquisitions and Other Reorganizations",
    details: `Information collected pursuant to this Privacy Policy, including Personal Information, may be disclosed to one or more third parties in connection with any change of ownership or control in Interstride’s business (whether by merger, sale, or otherwise), or any other reorganization or joint venture, or assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceeding), and any such information may be used by such third party in accordance with this Privacy Policy. Equally, information collected pursuant to this Privacy Policy, including Personal Information, may be disclosed to one or more third parties in connection with any acquisition or merger carried out by Interstride of such third parties.`,
  },
  {
    id: 14,
    category: "Data deletion request",
    details: `For any requests related to data deletion or removal of personal information, please send us your request by emailing us at <a href="mailto:contact@interstride.com">contact@interstride.com</a>. We will remove your personal information from our systems at our earliest convenience.`,
  },
  {
    id: 15,
    category: "Contact Us",
    details: `If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us:<br/><br/>
    Email: <a href="mailto:contact@interstride.com">contact@interstride.com</a><br/>
    Address: 5127 Meadow Creek Dr., Austin, TX 78745<br/><br/>
    Interstride’s use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.
    `,
  },
];

export const FAQ = [
  {
    id: 1,
    category: "Welcome tutorial",
    icon: WelcomeTutorialGuideIcon,
  },
  {
    id: 2,
    category: "General",
    icon: GeneralGuideIcon,
    QA: [
      {
        id: 1,
        question: "What is Interstride?",
        answer:
          "Interstride is a higher education technology company that helps colleges and universities attract, engage, and retain international students.<br/><br/>Interstride for Admissions is used by admissions and enrollment teams to attract and engage prospective international students throughout their entire admissions and pre-enrollment journey.<br/><br/>Career centers use Interstride Career Portal to support their international students and alumni with career success, including job search, networking, and resources on career readiness and immigration.",
      },
      {
        id: 2,
        question: "Can I still access Interstride as an enrolled student?",
        answer:
          "Yes, if your university or college is partnered with Interstride’s career portal, which allows you to find international-friendly jobs and internships, network and build community, and access resources on career readiness and immigration.<br/><br/>To see if your school is partnered with Interstride’s career portal, go to <a href='https://student.interstride.com/' target='_blank'>https://student.interstride.com</a>",
      },
      {
        id: 3,
        question: "Is there an Interstride app?",
        answer:
          "No, Interstride is currently a web application at the moment. Our web application is mobile-friendly.",
      },
      {
        id: 4,
        question:
          "Does Interstride have options for students with accessibility needs?",
        answer:
          "Yes. Students can enable the ‘Enable Accessibility Mode’ feature in the Profile section.",
      },
      {
        id: 5,
        question: "How do I report a technical issue I’m experiencing?",
        answer:
          "Send us an email at <a href='mailto:contact@interstride.com'>contact@interstride.com</a>. We will respond to you in 1-2 days.",
      },
    ],
  },
  {
    id: 3,
    category: "Profile",
    icon: ProfileGuideIcon,
    QA: [
      {
        id: 1,
        question: "Who can see my Interstride profile?",
        answer: "Only the school administrators can see your profile.",
      },
      {
        id: 2,
        question: "How do I change my password?",
        answer:
          "To change your password, sign out and click on Forgot Password to reset your password.",
      },
      {
        id: 3,
        question: "How do I delete my account?",
        answer:
          "You can delete your account by visiting <b>My Profile > Personal > Close account.</b>",
      },
      {
        id: 4,
        question: "How do I set up or adjust my notifications?",
        answer:
          'To view your notifications, click the bell icon next to your profile picture in the upper-right corner. Go to "Notifications" and click "Notification Settings." You can adjust or turn off push and email notifications for networking and job alerts.',
      },
    ],
  },
  {
    id: 4,
    category: "Chat & Community",
    icon: ChatGuideIcon,
    QA: [
      {
        id: 1,
        question:
          "How do I connect with international students and alumni at the university I’m interested in?",
        answer:
          "Go to Network to see and connect with ambassadors. You can view their profiles and filter by their year, majors, nationality, and other characteristics. To connect, click Message to say hi!<br/><br/>Every ambassador on Interstride for Admissions has agreed to be on the platform and is eager to speak with applicants and prospective students like you! They are fellow international students who have been in your shoes before.",
      },
      {
        id: 2,
        question:
          "How do I connect with other international students interested in applying to or attending this university?",
        answer:
          "Yes, the best way to connect with other peers during the admissions process is through Communities.",
      },
      {
        id: 3,
        question:
          "Can I chat with university administrators and admissions counselors through Interstride?",
        answer:
          "You can chat directly with university administrators if they have made their profiles visible to prospective students.",
      },
      {
        id: 4,
        question: "How do I delete, mute, or report a chat?",
        answer:
          "Click on the three dots on the screen's upper-right corner (above the person’s icon) to delete, mute, or report your chat.",
      },
      {
        id: 5,
        question: "What are Communities?",
        answer:
          "Interstride Communities are like Facebook Groups. Students may join and post content and updates on a Community.",
      },
      {
        id: 6,
        question:
          "What is a group chat, and how is it different from a Community?",
        answer:
          "A chat group is private, direct messaging between two or more people, whereas a Community is a public page that students may join and follow.",
      },
      {
        id: 7,
        question: "How do I create a new group chat or leave a group chat?",
        answer:
          "Go to Network > Messages > and click the blue + button to the right of the search bar to create a new chat or group chat. To leave a group chat, click on the three dots in the upper right-hand corner of the screen (above the group icon) to leave, mute, or report a group chat.",
      },
      {
        id: 8,
        question:
          "How do I report inappropriate behavior on a Community or block a user on Interstride?",
        answer:
          "Report any complaints, abusive language, or inappropriate behavior and content by emailing us at <a href='mailto:contact@interstride.com'>contact@interstride.com</a>.",
      },
      {
        id: 9,
        question: "How do I manage notifications on Communities I follow?",
        answer:
          "Click the bell icon next to your profile in the upper-right corner > select Communities > and adjust notifications to your preferences.",
      },
    ],
  },
  {
    id: 5,
    category: "Webinar, Resources & Tools",
    icon: ResourceGuideIcon,
    QA: [
      {
        id: 1,
        question: "What webinars do you offer, and how do I register for one?",
        answer:
          "Each month, Interstride offers a variety of webinars to help you choose institutions, navigate the application process, answer visa and immigration questions, and more.<br/><br/>To register for webinars, go to Learn > Webinars. Add the webinar event details to your calendar so you don’t miss it! If you miss a webinar, you can access the recording on the Interstride portal afterward.",
      },
      {
        id: 2,
        question: "I cannot attend a webinar. How do I access the recording?",
        answer:
          "We recommend you register for a webinar even if you cannot attend. If an Interstride partner hosts the webinar, they may email you a link to the recording afterward.<br/><br/>If Interstride hosts a webinar, go to Learn > Webinars > On Demand. You can access the recording link here and watch it at your convenience.",
      },
      {
        id: 3,
        question:
          "Where can I find relevant articles, videos, and resources on studying in the U.S.?",
        answer:
          "Go to <b>Learn > Resources</b> for articles, videos, and other resources. Your university will also add recommendations and resources for you, so be sure to check often for new updates.",
      },
      {
        id: 4,
        question:
          "How do I save a resource and view my library of saved resources?",
        answer:
          "Click the star to save a resource. You can view your saved videos and resources at any time in the Saved Resources section in the bottom-right corner of the Resources page.",
      },
      {
        id: 5,
        question: "What student services do you offer?",
        answer:
          "Interstride offers service recommendations and resources for various topics, including scholarships, credit cards, insurance, travel, and more. Browse them all by going to <b>Learn > Student Services</b>. Your university will also add recommendations and resources for you, so check often for new updates.",
      },
      {
        id: 6,
        question: "What is the Checklist, and how do I create one?",
        answer:
          "The Tools checklist is great for organizing and prioritizing tasks throughout your job search. To create a checklist, go to <b>Tools > Checklists</b>. Add tasks by clicking the ‘Add New Task’ button, and add and edit subtasks within each task. While you can create personalized checklists, Interstride offers several curated checklists with specific resources to help you complete each task.",
      },
      {
        id: 7,
        question:
          "Can I receive alerts for new resources or student services added to the Interstride platform?",
        answer:
          'Yes. You can sign up for alerts from any one of our resource categories, including General Career Advice, Networking, Non-Career Resources, and more. Similarly, you can subscribe to student services alerts for scholarships, housing, and more. To view and adjust your notifications, click the bell icon next to your profile picture in the upper-right corner. Go to "Notifications" and click "Notification Settings” to adjust or turn off push and email notifications.',
      },
    ],
  },
];

export const WELCOME_MESSAGE_FRESH_CHAT =
  "Hi! I’m {first_name} from {country}. I’m {user_type} at {university}, feel free to ask me any questions!";

export const INTERSTRIDE_LOGO_URL =
  "https://interstride.s3.amazonaws.com/branding/brand-header-default-logo.svg";

export const INTERSTRIDE_EMBLEM =
  "https://interstride.s3.amazonaws.com/branding/square-emblem-logo.svg";

export const US_COORDIANTES = {
  lat: 38.176296082711296,
  lng: -104.08870496995037,
};

export const DOB_FORMAT = "DD/MM/YYYY";

export const COURSES_LIST = [
  {
    id: "masterclass",
    name: "Ultimate guide to US admissions",
    url: "https://learn.interstride.com/masterclass",
    permission: "masterclass",
  },
  {
    id: "f1_student_visa",
    name: "A guide to the F-1 student visa",
    url: "https://learn.interstride.com/f1_student_visa",
    permission: "f1_student_visa",
  },
];

export const MASTERCLASS_MODULES = [
  {
    id: "module1",
    name: "Module 1",
    title: "Course intro",
    desc: "What to expect from this course",
    url: "https://learn.interstride.com/masterclass/module1/",
    icon: MasterclassModule1Icon,
  },
  {
    id: "module2",
    name: "Module 2",
    title: "Admissions process",
    desc: "A brief roadmap from start to arrival",
    url: "https://learn.interstride.com/masterclass/module2/",
    icon: MasterclassModule2Icon,
  },
  {
    id: "module3",
    name: "Module 3",
    title: "College search",
    desc: 'Finding the "best fit" for you',
    url: "https://learn.interstride.com/masterclass/module3/",
    icon: MasterclassModule3Icon,
  },
  {
    id: "module4",
    name: "Module 4",
    title: "Cost of education and financial aid",
    desc: "Paying for college and getting financial aid",
    url: "https://learn.interstride.com/masterclass/module4/",
    icon: MasterclassModule4Icon,
  },
  {
    id: "module5",
    name: "Module 5",
    title: "Applications",
    desc: "Applying to college in the US as an international student",
    url: "https://learn.interstride.com/masterclass/module5/",
    icon: MasterclassModule5Icon,
  },
  {
    id: "module6",
    name: "Module 6",
    title: "Your college journey begins",
    desc: "Acceptance, enrollment, and preparation for the US",
    url: "https://learn.interstride.com/masterclass/module6/",
    icon: MasterclassModule6Icon,
  },
];
