import React from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./CalculatorItem.module.scss";
import USDTextbox from "../../atoms/USDTextbox/USDTextbox";
import InfoIconPopup from "../../atoms/InfoIconPopup/InfoIconPopup";

const cx = createModuleStyleExtractor(styles);

export default function CalculatorItem({ item, value, onChange = () => {} }) {
  return (
    <div className={cx("m-calculator-item")}>
      <div className={cx("m-calculator-item__title")}>
        <h3>
          {item.name}
          {item.tooltip && (
            <InfoIconPopup
              margin={"2px 0 0 5px"}
              popoverContent={<>{item.tooltip}</>}
              variant="extra-small"
            />
          )}
        </h3>
      </div>
      {item.adjustment_type === "fixed" ? (
        <div className={cx("m-calculator-item__fixed")}>
          <span>${item.amount}</span>
        </div>
      ) : (
        <USDTextbox
          value={value}
          onChange={onChange}
          name={item.id}
          placeholder={"Enter a value"}
        />
      )}
    </div>
  );
}
